import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/2VQhDg0P4ho"
    notesSrc="https://www.docdroid.net/tAhsL0y/bible-group-homework-3-78.pdf"
    notesText="Bible Group Homework"
  >
    <SEO title="Love Song - The Wedding Day" />
  </Layout>
)

export default SermonPost
